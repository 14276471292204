<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" @input="getCourse"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 부서 -->
          <c-dept type="search" :isFirstValue="false" name="deptCd" v-model="searchParam.deptCd"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육과정 -->
          <c-select
            :comboItems="courseItems"
            type="search"
            itemText="educationCourseName"
            itemValue="eduCourseId"
            name="eduCourseId"
            label="LBL0002930"
            v-model="searchParam.eduCourseId"
          >
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육종류1 -->
          <c-select
            type="search"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="LBL0002933"
            v-model="searchParam.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 법정교육여부 -->
          <c-select
            type="search"
            :comboItems="lawCheckItems"
            itemText="codeName"
            itemValue="code"
            name="legalEducationFlag"
            label="LBL0002800"
            v-model="searchParam.legalEducationFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 법정교육대상자 도래일 목록 -->
    <c-table
      ref="table"
      title="LBL0002946"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :checkClickFlag="false"
      rowKey="eduEducationId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="LBLSEARCH"  icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customDate'">
          <span>
            {{props.row.nextDate}}
            <q-badge v-if="props.row.arrivalFlag === 'Y'" color="negative" :label="props.row.diffDay+'일전'" class="blinking" />
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "education-arrival-date",
  props: {
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'mergeGroup' },
          { index: 1, colName: 'mergeGroup' },
          { index: 2, colName: 'mergeGroup' },
          { index: 3, colName: 'mergeGroup' },
          { index: 4, colName: 'mergeGroup' },
        ],
        columns: [
          {
            name: "plantName",
            field: "plantName",
            // 사업장
            label: "LBLPLANT",
            style: 'width:80px',
            align: "center",
            sortable: true,
          },
          {
            name: "deptName",
            field: "deptName",
            // 부서
            label: "LBLDEPT",
            align: "center",
            style: 'width:120px',
            sortable: true,
          },
          {
            name: "userName",
            field: "userName",
            // 대상자
            label: "LBL0002864",
            align: "center",
            style: 'width:120px',
            sortable: true,
          },
          {
            name: "jobName",
            field: "jobName",
            // 직책
            label: "LBLPOSITION",
            align: "center",
            style: 'width:80px',
            sortable: true,
          },
          {
            name: "spotName",
            field: "spotName",
            // 직급
            label: "LBLSPOT",
            align: "center",
            style: 'width:80px',
            sortable: true,
          },
          // {
          //   name: "totalAttendanceTime",
          //   field: "totalAttendanceTime",
          //   // 총 교육시간
          //   label: "총 교육시간",
          //   align: "center",
          //   style: 'width:80px',
          //   sortable: true,
          // },
          {
            name: "educationCourseName",
            field: "educationCourseName",
            // 교육과정
            label: "LBL0002930",
            style: 'width:250px',
            align: "left",
            sortable: true,
          },
          {
            name: "educationKindName",
            field: "educationKindName",
            // 교육종류1
            label: "LBL0002933",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationEndDate",
            field: "educationEndDate",
            // 최근교육 이수일
            label: "LBL0002947",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationCycleName",
            field: "educationCycleName",
            // 법정교육주기
            label: "LBL0002802",
            style: 'width:90px',
            align: "center",
            sortable: true,
          },
          {
            name: "customDate",
            field: "customDate",
            // 다음예정 교육일
            label: "LBL0002948",
            style: 'width:100px',
            type: 'custom',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        deptCd: '',
        eduCourseId: null,
        educationKindCdLarge: null,
        legalEducationFlag: null,
      },
      lawCheckItems: [
        { code: 'Y', codeName: 'O', },
        { code: 'N', codeName: 'X', },
      ],
      courseItems: [],
      editable: true,
      listUrl: "",
      courseUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.courseUrl = selectConfig.sop.edu.course.list.url;
      this.listUrl = selectConfig.sop.edu.arrivaldate.list.url;
      // code setting
      // list setting
      this.getCourse();
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getCourse() {
      this.searchParam.eduCourseId = null;
      if (this.searchParam.plantCd) {
        this.$http.url = this.courseUrl;
        this.$http.type = 'GET';
        this.$http.param = {plantCd: this.searchParam.plantCd, useFlag: 'Y'};
        this.$http.request((_result) => {
          this.courseItems = _result.data;
        },);
      } else {
        this.courseItems = [];
      }
    },
  },
};
</script>
